<script>
import { mapGetters } from 'vuex';
import SearchBar from '@/components/SearchBar.vue';
import NewsCard from './components/NewsCard.vue';
import VueMeta from 'vue-meta'
import {i18n} from "vue-lang-router";





export default {
  
  name: 'home',

  computed: {
    ...mapGetters('news', [
      'articles',
    ]),
  },
  created() {
    document.title = i18n.t("tempus");
  },

  metaInfo() {
    return {
      title: "Tempus Bildbyrå – bildarkivet för historia och kulturarv!"
    };
  },

  methods: {
    fetchResult(query) {
      console.log("1");
      //this.$router.push({ path: 'library-search', query: { q: query } });
    },
  },

  components: {
    SearchBar,
    NewsCard,
  },
};
</script>

<template>
  <div class="home">

    <div class="hero-top-content px-6 py-4">
      <h1>Tempus Bildbyrå – bildarkivet för historia och kulturarv!</h1>

      <div class="home-description">
        <div class="home-description-left">
          <p>Tempus Bildbyrå är bildarkivet för historia och kulturarv. Bildskatter
          ur vårt svenska och europeiska kulturarv av bokillustrationer görs här
          bekvämt åtkomliga för dig som är intresserad av den visuella aspekten av
          historia. Här finns ett brett spektrum av motiv: alltifrån geografiska
          vyer och porträtt av kända personer till bilder av historiska händelser
          och föremål.
          <br/><br/>
          Du hittar bilder genom att göra en fritextsökning eller genom att
          bläddra bland kategorierna. Vilket som är enklast beror på vad för slags
          motiv du letar efter. När du har hittat en intressant bild kan du hitta
          fler liknande genom att klicka på bildens ämnesord eller kategorier.
        </p>
        </div>
        <div class="home-description-right">
            <img src="@/assets/homeImage.jpg" alt="Hos Tempus Bildbyrå hittar du bokillustrationer.">
        </div>
      </div>
    </div>

    <div class="search-hero">
      <search-bar @onSearch="fetchResult"/>
      <a href="/sv/kategorier"><img src="@/assets/kategorier.png" alt="Klicka här för att bläddra bland kategorierna."></a>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .hero-top-content{
      text-align: left;
       padding: 0 25%;
      margin: 20px auto;

      @media only screen and (max-width: 992px) {
         padding: 0 20px;
      }

      h1{
        font-size: 30px;
        font-weight: 500;
        line-height: 36px;
        margin-bottom: 20px;
      }

      .home-description{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        .home-description-left{
          p{
            font-size: 16px;
            font-weight: 500;
            line-height: 29px;
          }
        }
        
        .home-description-right{
          border: 1px solid gray;
          padding: 5px;
        }

      }

       @media only screen and (max-width: 992px){
          .home-description{
             flex-direction: column;
          }
         }
  }
  .search-hero {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // height: 500px;
    padding: 0 25%;
    align-items: center;
    margin-bottom: 20px;

    @media only screen and (max-width: 992px) {
         padding: 0 20px;
      }

    // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('http://novaiv.historisktbildarkiv.se/images/nova/bg-min.jpg');
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;

    @media screen and (max-width: 640px) {
      padding: 0 1rem;
      padding: 0 5%;
    }
  }

  .latest-news {
    padding: 0 2em;
    text-align: left;
    overflow-x: scroll;

    h2 {
      margin: 1em 0;
    }

    .news-card {
      margin-right: 20px;
    }
  }
</style>

